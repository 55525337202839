<script>
import ResultFilter from "@/components/ResultFilter.vue";
import KazunosukeItemList from "@/components/KazunosukeItemList.vue";

export default {
  name: "KazunosukeResult",
  components: {
    ResultFilter,
    KazunosukeItemList,
  },
  props: {
    items: Object,
    category: String,
    mappedAnswers: Array,
  },
  data() {
    return {
      // 画像のルートURL
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isBeforeMounted: true,
      genre: "skin-care",
      kazunosukeCategory: '',
      // フィルター
      /* 価格 */
      minPrice: 0,
      maxPrice: 10000,
      minPriceRange: 0,
      maxPriceRange: 10000,
      filterStep: 500,
      /* 基礎化粧品, スペシャルケア */
      isSkinLotionSelected: true,
      isMilkCreamSelected: false,
      isAllInOneSelected: false,
      isCeramideSelected: false,
      isVitaminCSelected: false,
      isRetinolSelected: false,
      isNiacinamideSelected: false,
      isTranexamicAcidSelected: false,
      /* 洗顔 */
      isPoresSelected: false,
      isRoughSkinSelected: false,
      isOilySkinSelected: false,
    };
  },
  computed: {
    topMessage() {
      if (this.$i18n.locale == 'ja') {
        return this.items.length == 0
          ? "大変申し訳ございません。<br>お客様の条件にあうオススメ商品がありませんでした。<br>条件を変えて再度、コンシェルジュにご相談ください。"
          : "<span style='color: #555; font-size:24px;'>かずのすけ</span>&nbsp;があなたにオススメする商品はこちらです。";
      } else if (this.$i18n.locale == 'zh') {
        return this.items.length == 0
          ? "非常抱歉。<br>没有符合您条件的推荐商品。<br>请更改条件后再次咨询我们的顾问。"
          : "这是<span style='color: #555; font-size:24px;'>Kazunosuke</span>&nbsp;为您推荐的商品。";
      } else {
        return this.items.length == 0
          ? "Sorry, there are no recommended products that match your criteria.<br>Please change your criteria and consult our concierge again."
          : "Here are the products that <span style='color: #555; font-size:24px;'>Kazunosuke</span>&nbsp;recommends for you.";
      }
    },
    isItemExist() {
      return this.items.length > 0;
    },
    filteredItems() {
      return this.items.filter((item) => {
        return (
          this.minPrice <= item.official_price &&
          item.official_price <= this.maxPrice &&
          this.isMatchCategoryFilter(item, this.mappedAnswers[2])
        );
      }).sort((a,b) => a.order - b.order)
    },
  },
  mounted() {
    if (this.kazunosukeCategory == "スペシャルケア") {
      this.minPrice=0
      this.maxPrice=20000
      this.minPriceRange=0
      this.maxPriceRange=20000
      this.filterStep=1000
    } else {
      this.minPrice=0
      this.maxPrice=10000
      this.minPriceRange=0
      this.maxPriceRange=10000
      this.filterStep=500
    }
  },
  beforeMount() {
    this.isBeforeMounted = false;
    this.kazunosukeCategory = this.mappedAnswers[2]
  },
  methods: {
    isMatchCategoryFilter(item, category) {
      if (category == "基礎化粧品") {
        return this.isInSelectedCategory(item) && this.isInSelectedIngredient(item)
      } else if (category == "スペシャルケア") {
        return this.isInSelectedIngredient(item)
      } else if (category == "洗顔") {
        return this.isInSelectedFacewashCategory(item)
      } else { // クレンジングの場合
        return true
      }
    },
    // 基礎化粧品のフィルター
    isInSelectedCategory(item){
      return (this.isSkinLotionSelected && item.appliedCategory == "化粧水") || 
                (this.isMilkCreamSelected && item.appliedCategory == "乳液&クリーム") || 
                (this.isAllInOneSelected && item.appliedCategory == "オールインワン")
    },
    isInSelectedIngredient(item){
      // マッパーの準備
      const ingredientMapper = {
        "ceramide": this.isCeramideSelected,
        "vitamin": this.isVitaminCSelected,
        "retinol": this.isRetinolSelected,
        "amide": this.isNiacinamideSelected,
        "tranexamic": this.isTranexamicAcidSelected
      }
      return Object.keys(ingredientMapper)
                   .map(ing => 
                      item.features[ing] ||
                      (!item.features[ing] && !ingredientMapper[ing])
                    )
                   .every(x=>x)
    },
    // 洗顔のフィルター
    isInSelectedFacewashCategory(item){
      // マッパーの準備
      const facewashMapper = {
        "pores": this.isPoresSelected,
        "damaged": this.isRoughSkinSelected,
        "oily": this.isOilySkinSelected
      }
      return Object.keys(facewashMapper)
                   .map(feature =>
                      item.features[feature] ||
                      (!item.features[feature] && !facewashMapper[feature])
                   )
                   .every(x=>x)
    },
  }
};
</script>

<template>
  <transition name="fade" mode="out-in">
    <div v-if="isBeforeMounted">
      <section class="firstTouch">
        <div class="firstTouch_container">
          <img src="@/assets/about/eye-catching-drop.png">
        </div>
      </section>
    </div>
    <div v-else>
      <section class="result_itemSuggestion_title_container">
        <div class="result_itemSuggestion_title_inner">
          <h1 v-html="topMessage" />
        </div>
      </section>
      <section class="item_category_section">
        <div class="item_category_container">
          <div class="mappedAnswers">
            <div
              v-for="answer in mappedAnswers"
              :key="answer"
            >
              <p>
                {{ answer[$i18n.locale] }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="suggested_items_section">
        <div class="suggested_item_filter">
          <result-filter
            v-model:minPrice="minPrice"
            v-model:maxPrice="maxPrice"
            v-model:minPriceRange="minPriceRange"
            v-model:maxPriceRange="maxPriceRange"
            v-model:filterStep="filterStep"
            v-model:isSkinLotionSelected="isSkinLotionSelected"
            v-model:isMilkCreamSelected="isMilkCreamSelected"
            v-model:isAllInOneSelected="isAllInOneSelected"
            v-model:isCeramideSelected="isCeramideSelected"
            v-model:isVitaminCSelected="isVitaminCSelected"
            v-model:isRetinolSelected="isRetinolSelected"
            v-model:isNiacinamideSelected="isNiacinamideSelected"
            v-model:isTranexamicAcidSelected="isTranexamicAcidSelected"
            v-model:isPoresSelected="isPoresSelected"
            v-model:isRoughSkinSelected="isRoughSkinSelected"
            v-model:isOilySkinSelected="isOilySkinSelected"
            :category="kazunosukeCategory"
          />
        </div>
        <kazunosuke-item-list
          :genre="genre"
          :category="kazunosukeCategory"
          :items="filteredItems"
        />
      </section>
      <section class="concierge_info">
        <img :src="ROOT_IMAGE_URL + '/face-icon/kazunosuke.png'">
        <div class="concierge_profile">
          <span>{{ $t('kazunosukeTitle') }}</span>
          <h2>{{ $t('kazunosukeName') }}</h2>
          <router-link
            :to="{
              name: 'Concierges',
              params: {
                incomingGenre: 'skincare',
                incomingConcierge: 'kazunosuke',
              },
            }"
            class="profile_btn"
          >
            {{ $t('conciergeSeeProfile') }}
          </router-link>
        </div>
      </section>
      <section class="guiding_mykarte">
        <p>
          {{ $t('resultToMykarte1') }}<br>
          {{ $t('resultToMykarte2') }}
        </p>
        <router-link to="/mykarte">
          <div class="skinCheck_contents">
            <div class="skinCheck_contents_imgBox">
              <img
                src="@/assets/about/karte.png"
                class="skinCheck_btn_img"
              >
            </div>
            <p class="skinCheck_btn_title skinCheck_btn">
              {{ $t('mykarte') }}
              <img src="@/assets/about/arrow.png">
            </p>
          </div>
        </router-link>
      </section>
    </div>
  </transition>
</template>

<style scoped>
/* メイン */
main {
  font-size: 14px;
  max-width: 414px;
  margin: 0 auto;
}
h1 {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
}
h2 {
  font-size: 22px;
  font-weight: bold;
}
h3 {
  font-size: 20px;
}
a {
  text-decoration: none;
  color: #777;
}
.result_itemSuggestion_title {
  background-color: #fff;
  padding: 0 40px;
}
.result_itemSuggestion_title_container {
  padding: 0 30px;
  background-color: #f5eae7;
}
.result_itemSuggestion_title_inner {
  padding: 30px 0 0 0;
}
.result_itemSuggestion_title_container span {
  font-size: 24px;
}
.result_itemSuggestion_title_container span {
  font-size: 24px;
}
.concierge_info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}
.concierge_info img {
  width: 90px;
  border-radius: 50%;
  border: #f29f8f solid 1px;
}
.concierge_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}
.concierge_profile span {
  font-size: 14px;
}
.concierge_profile h2 {
  color: #555;
  padding: 5px 0 0 0;
}
.concierge_profile a {
  padding: 11px 14px;
  background-color: #fadad2;
  font-size: 16px;
}
.profile_btn {
  padding: 11px 14px;
  background-color: #fadad2;
  margin-top: 7px;
}
.item_category_section {
  background-color: #f5eae7;
  padding: 20px;
}
.suggested_item_filter {
  background-color: #f5eae7;
  padding: 0 20px;
}
.item_category {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.guiding_mykarte{
  background-color: #FADAD2;
  padding: 50px 0 55px;
}
.guiding_mykarte p{
  font-size: 18px;
  text-align: center;
  padding-bottom: 20px;
  line-height: 24px;
}
.skinCheck_contents{
  display: flex;
  align-items: center;
  padding: 20px;
  opacity: 1;
  width: 80%;
  background-color: #fff;
  margin: 0 auto;
  border: 3px solid #E6A393;
  border-radius: 20px;
  animation: blinkEffect 1s ease infinite alternate;
}
.skinCheck_contents p{
  font-size: 26px;
  font-weight: bold;
  padding-bottom: 0;
  padding-top: 5px;
  line-height: 26px;
}
@keyframes blinkEffect {
  0% {
    box-shadow: none;
    opacity: 0.7;
  }
  100% {
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    opacity: 1;
  }
}
.skinCheck_contents_imgBox{
  width: 25%;
  text-align: center;
}
.skinCheck_contents_imgBox img{
  width: 40px;
}
.skinCheck_btn_title{
  width: 80%;
  padding-left: 5px;
  text-align: center; 
  font-size: 20px;
}
.skinCheck_btn_title img{
  width: 40px;
  padding: 0 0 3px 0;
}
.skinCheck_btn{
  font-weight: bold;
  font-size: 26px;
}
</style>